import { Container } from './styles'
 import reactIcon from '../../assets/react-icon.svg'
import linkedin from '../../assets/linkedin.svg'
// import githubIcon from '../../assets/github.svg'
// import whatsapp from '../../assets/whatsapp.svg'
// import telegram from '../../assets/telegram.svg'
// import instagramIcon from '../../assets/instagram.svg'
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://abhaypp.com" className="logo">
        <span>www.abhay</span>
        <span>pp.com</span>
      </a>
      <div>
        <p>
          This Website was made with <img src={reactIcon} alt="React" />
          {/* <span>❤️</span> */}
        </p>
      </div>
      <div className="social-media">
        <a
          href="https://in.linkedin.com/in/abhay-prajapati-321790348"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
        {/* <a
          href="https://github.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a> */}
        {/* <a
          href="https://api.whatsapp.com/send/?phone=%2B91&text=Hello+Abhay"
          target="_blank"
          rel="noreferrer"
        > 
          <img src={whatsapp} alt="Whatsapp" />
        </a> */}
        {/* <a
          href="https://t.me/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a> */}
        {/* <a
          href="https://www.instagram.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a> */}
      </div>
    </Container>
  )
    }
